<template>
  <div class="container-page">
    <HeaderComp title="" url="#" />
    <div class="card-page">
      <div class="form-order">
        <div class="title-page mb-3">Pembayaran</div>
        <div class="card-shadow mb-3">
          <div class="content-space-between event-calendar">
            <div><img src="../assets/img/icon/fi-rr-calendar-grey.png" class="me-2 icon-12" /><span>Tanggal Event</span></div> <span>{{
              formatDateTimesDay(eventDate) }}</span>
          </div>
          <div class="title-event content-space-between">
            <img :src="imgBanner" class="banner-small me-3"> <span class="w-100">{{ eventName }}</span>
          </div>
          <div class="content-space-between">
            <p class="m-0 text-black font-size-12">Selesaikan Pemesanan Dalam</p>
            <p class="m-0"><span class="badges-timer m-0">{{
              hoursTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span><span
                class="font-size-12 font-weight-600 text-primary"> : </span><span class="badges-timer m-0">{{
              minutesTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span> <span
                class="font-size-12 font-weight-600 text-primary">:</span> <span class="badges-timer m-0">{{
              secondsTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span></p>
          </div>
        </div>
        <div class="card-no-shadow mb-3">
          <div class="time-exp mb-3">
            <p class="font-size-12 font-weight-400 text-black m-0 mb-1">
              Lakukan pembayaran sebelum
            </p>
            <p class="font-size-12 font-weight-600 m-0 badge-grey-container">
              {{ formatDateTimes(expired_date) }}
            </p>
          </div>
          <div class="method-pay mb-3">
            <p class="font-size-12 font-weight-400 text-black m-0 mb-2">
              Metode Pembayaran
            </p>
            <div class="font-size-14 font-weight-600 m-0 content-space-between col-centered">
              <img :src="paymentMethod?.logo" class="logo-va me-2">
              <p class="w-100 m-0">{{ paymentMethod?.name }}</p>
            </div>
          </div>
          <div class="va-num mb-3">
            <p class="font-size-12 font-weight-400 text-black m-0 mb-1">
              Nomor Virtual Account
            </p>
            <div class="m-0 badge-grey-container content-space-between">
              <div class="m-0 font-size-16 font-weight-600">{{ dataPayment?.payment_account_no }}</div>
              <div class="m-0 font-size-12 font-weight-600 text-primary cursor-pointer"
                @click="copyNum(dataPayment?.payment_account_no)">Salin <img src="../assets/img/icon/fi-rr-copy-alt.png" class="icon-12" /></div>
            </div>
          </div>
          <div class="total-amt mb-3">
            <p class="font-size-12 font-weight-400 text-black m-0 mb-1">
              Total Pembayaran
            </p>
            <div class="m-0 badge-grey-container content-space-between">
              <div class="m-0 font-size-16 font-weight-600">{{ formatCurrency(dataPayment?.total_amount) }}</div>
              <div class="m-0 font-size-12 font-weight-600 text-primary cursor-pointer" @click="showDetail = true">Rincian</div>
            </div>
          </div>
          <div class="toast-fixed info">
            <div class="message-claimed">
              <img src="../assets/img/fi-rr-info.png">
              <span class="ms-2">Lakukan pembayaran sesuai dengan Bank dari Virtual Account yang dipilih</span>
            </div>
          </div>
        </div>
        <div class="font-size-12 font-weight-700 mb-2">Cara Pembayaran</div>
        <div class="info-payment mb-2" v-for="(item, key) in dataPayment?.instruction_payment" :key="key">
          <div class="header-title" v-b-toggle="'instruct_' + key">
            <p class="sub-sub-title text-dark font-weight-600 title-page d-flex m-0">
              <span class="w-100">{{ item?.title }}</span>
              <span class="icon-arrow when-closed"><img src="../assets/img/icon/fi-rr-angle-small-down.png" class="icon-12"/></span>
              <span class="icon-arrow when-open"><img src="../assets/img/icon/fi-rr-angle-small-up.png" class="icon-12"/></span>
            </p>
          </div>
          <b-collapse class="container-exp" :id="'instruct_' + key">
            <div class="m-0 p-2 font-size-10" v-html="getMarked(item?.detail)"></div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div :class="['footer-form', { 'rounded-footer': showDetail }]">
      <div class="container-summary" v-if="showDetail == true">
        <div class="summary-detail mb-2">
          <div class="summary-title font-weight-600">Rincian Pembayaran</div>
          <b-icon-x-lg class="summary-detail-close cursor-pointer" @click="showDetail = false"></b-icon-x-lg>
        </div>
        <div class="card-no-shadow mb-3">
          <div class="container-ticket">
            <img src="../assets/img/icon/fi-rr-ticket-grey.png" class="icon-12 me-1"> <span class="font-weight-600">Tiket Kamu</span>
            <div v-for="(item, key) in dataEvent?.order_summary" :key="key">
              <div class="sub-total-ticket">
                <span>{{ item?.category_name }}</span>
                <span>{{ formatCurrency((item?.sum_price / item?.sum_quantity)) }}</span>
              </div>
              <div class="total-ticket mb-2">
                <span class="font-weight-700 font-size-12">{{ item?.sum_quantity }}x {{ item?.category_name }}</span>
                <span class="font-weight-700 font-size-12">{{ formatCurrency(item?.sum_price) }}</span>
              </div>
            </div>
            <div class="total-ticket mb-3 mt-3">
              <span class="font-weight-400 font-size-12">Diskon Promo {{dataPayment?.referral_code_item?.discount_percentage}}%</span>
              <span class="font-weight-600 font-size-12">- {{ formatCurrency(dataPayment?.price_reduction) }}</span>
            </div>
            <div class="total-ticket mb-3 mt-3">
              <span class="font-weight-400 font-size-12">Biaya Layanan</span>
              <span class="font-weight-600 font-size-12">{{ formatCurrency(dataPayment?.admin_fee) }}</span>
            </div>
            <div class="summary-qty mt-2">
              <span>Total Pembayaran</span>
              <span class="font-weight-600">{{ formatCurrency(dataPayment?.total_amount) }}</span>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-primary w-100" type="button" @click="gotoEvent()">
        Detail Event
      </button>
    </div>
    <Loading :isLoading="loading" />
    <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
        <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
        <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
        <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
          Kasih</button>
      </div>
    </b-modal>
    <b-modal size="sm" ref="confirm-modal" id="confirm-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <p class="font-size-12 font-weight-600 mb-2">Apakah Anda yakin ingin meninggalkan halaman ini?</p>
        <p class="font-size-10 font-weight-400 mb-4">Anda harus membatalkan pesanan terlebih dahulu untuk meninggalkan
          halaman ini</p>
        <div class="w-100 me-0 d-flex flex-row justify-content-end align-items-center">
          <button type="button" class="btn button-default text-primary me-3 font-size-10 font-weight-600"
            @click="$bvModal.hide('confirm-modal')">Cancel</button>
          <button type="button" class="btn button-rounded bg-danger font-size-10 font-weight-600 btn-xxsm"
            @click="$router.push('/detail-order')">Cancel Order</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import { marked } from 'marked';
export default {
  components: {
    Loading,
    HeaderComp,
    marked
  },
  data() {
    return {
      showDetail: false,
      isDisabledRef: false,
      loading: false,
      cancelOrder: false,
      paymentMethod: null,
      dataEvent: null,
      dataPayment: null,
      admin_fee: 0,
      listPayment: [],
      order_summary: [],
      intervalData: null,
      intervalPayment: null,
      hoursTimer: 0,
      minutesTimer: 0,
      secondsTimer: 0,
      imgBanner: null,
      eventDate: localStorage.getItem('event_date'),
      eventName: localStorage.getItem('event_name'),
      order_id: localStorage.getItem('order_id'),
      expired_date: null,
      terms: null,
      error: {
        title: "",
        message: ""
      }
    };
  },
  methods: {
    countdownTimer(date) {
      // let countdown = 30 * 60 * 1000;
      let like = this;
      var countDownDate = new Date(date).getTime();
      this.intervalData = setInterval(function () {

        var now = new Date().getTime();

        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(like.intervalData);
          const platform = localStorage.getItem('platform_key');
          const url_base = localStorage.getItem('base_url_event');
          // const token = localStorage.setItem('token', token);
          // if (platform == 'MGO_IOS' || platform == 'MGO_ANDROID') {
          //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          //   if (/android/i.test(userAgent)) {
          //     JSBridge.deleteToken(token); // eslint-disable-line
          //   } else {
          //     window.webkit.messageHandlers.deleteToken.postMessage(token);
          //   }
          // }
          localStorage.clear();
          localStorage.setItem('platform_key', platform);
          localStorage.setItem('base_url_event', url_base);
          localStorage.setItem('message_failed_payment', 'Mohon Maaf, waktu pembayaran tiket Anda menggunakan Virtual Account sudah melewati batas waktu.');
          localStorage.setItem('title_failed_payment', 'Pembayaran Kadaluwarsa');
          like.$router.push({ name: "FailedPayment", query: { type : "1" } });
        } else {
          like.hoursTimer = hours;
          like.minutesTimer = minutes;
          like.secondsTimer = seconds;
        }
      }, 1000);
    },
    getPaymentOrder() {
      const order = localStorage.getItem('detailOrder');
      const method = localStorage.getItem('choosePayment');
      this.dataEvent = JSON.parse(order);
      this.paymentMethod = JSON.parse(method);
      this.expired_date = this.dataEvent?.order_expired_date;
      this.countdownTimer(this.expired_date);
    },
    getPaymentOrderId() {
      this.loading = true;
      axios.get("/buyers/orders/payments/detail").then((res) => {
        // console.log(res)
        this.dataPayment = res.data.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error?.response?.data?.title || error?.response?.data?.message) {
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.loading = false;
          this.$router.push('/busy-server');
        }
      });
    },
    copyNum(data) {
      navigator.clipboard.writeText(data);
      window.webkit.messageHandlers.toastHandler.postMessage("Disalin");
    },
    getMarked(data) {
      return marked(data, { breaks: true });
    },
    gotoEvent() {
      const base_url_event = localStorage.getItem('base_url_event');
      window.location.href = base_url_event;
    },
    checkPayment() {
      axios.get("/buyers/orders/payments/detail").then((res) => {
        let status = res?.data?.data?.status;
        if (status !== 'PENDING') {
          clearInterval(this.intervalPayment)
          const platform = localStorage.getItem('platform_key');
          const url_base = localStorage.getItem('base_url_event');
          // const token = localStorage.setItem('token', token);
          // if (platform == 'MGO_IOS' || platform == 'MGO_ANDROID') {
          //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          //   if (/android/i.test(userAgent)) {
          //     JSBridge.deleteToken(token); // eslint-disable-line
          //   } else {
          //     window.webkit.messageHandlers.deleteToken.postMessage(token);
          //   }
          // }
          localStorage.clear();
          localStorage.setItem('platform_key', platform);
          localStorage.setItem('base_url_event', url_base);
          if (status == 'SUCCESS') {
            localStorage.setItem('success_payment', 'Selamat, pembayaran Tiket Anda menggunakan Virtual Account sudah berhasil!');
            this.$router.push({ name: "SuccessPayment", query: { type : "1" } });
          } else if (status == 'EXPIRED') {
            localStorage.setItem('message_failed_payment', 'Mohon Maaf, waktu pembayaran tiket Anda menggunakan Virtual Account sudah melewati batas waktu.');
            localStorage.setItem('title_failed_payment', 'Pembayaran Kadaluwarsa');
            this.$router.push({ name: "FailedPayment", query: { type : "1" } });
          } else if (status == 'FAILED') {
            localStorage.setItem('message_failed_payment', 'Mohon Maaf, pembayaran tiket Anda menggunakan Virtual Account tidak berhasil!');
            localStorage.setItem('title_failed_payment', 'Pembayaran Gagal');
            this.$router.push({ name: "FailedPayment", query: { type : "1" } });
          } else {
            localStorage.setItem('message_failed_payment', 'Mohon Maaf, pembayaran tiket Anda menggunakan Virtual Account tidak berhasil!');
            localStorage.setItem('title_failed_payment', 'Pembayaran Gagal');
            this.$router.push({ name: "FailedPayment", query: { type : "1" } });
          }
        }
      }).catch((error) => {
        if (error?.response?.data?.title || error?.response?.data?.message) {
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.$router.push('/busy-server');
        }
      });
    }
  },
  beforeDestroy() {
    localStorage.setItem('lastPathData', 'payment-detail');
    clearInterval(this.intervalData);
    clearInterval(this.intervalPayment);
  },
  created() {
    let likes = this;
    this.intervalPayment = setInterval(() => {
      likes.checkPayment();
    }, 5000);
  },
  mounted() {
    this.getPaymentOrder();
    this.getPaymentOrderId();
    localStorage.setItem('currentPath', '/payment-detail');
    let banner = localStorage.getItem('banner_new');
    if (banner) {
      let bannerSet = JSON.parse(localStorage.getItem('banner_new'));
      this.imgBanner = bannerSet;
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-va {
  width: 49px;
  height: 14px;
}

.payment-name {
  text-align: left;
}

.bg-logo-radio {
  background-color: #ffffff;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
}

.logo-radio {
  width: 16px;
  height: 5px;
}

.card-payment-method {
  background: #F5F7FA;
  padding: 4vw;
  border-radius: 8px;
}

.sub-title-container {
  font-size: 0.625rem;
  font-weight: 400;
  color: #000;
}

.disabled-qty {
  color: #647179 !important;
  cursor: not-allowed;
}

.rounded-footer {
  border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
}

.message-claimed img {
  margin-top: 3px;
  width: 0.625rem;
  height: 0.625rem;
}

.message-claimed span {
  font-size: 0.625rem;
}

.total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.sub-total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #647179;
  font-weight: 400;
}

.container-ticket i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}

.container-ticket {
  font-size: 0.75rem;
}

.container-ticket-card {
  padding-top: 9px;
  font-size: 0.75rem;
  color: #000;
}

.container-ticket-card i {
  font-size: 0.75rem;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  color: #AEAEB2;
}

.card-summary {
  padding: 2vw;
  border: 1px solid #E7ECF4;
  border-radius: 8px;
}

.summary-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.summary-detail i {
  font-size: 1rem;
  color: #8E8E93;
}

.summary-qty {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.link-text {
  text-decoration: underline;
}

.disclaimer-text {
  padding: 2vw;
  font-size: 0.625rem;
}

.text-qty {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-align: center;
  display: inline-flex;
}

.icon-arrow {
  font-size: 0.75rem;
  line-height: 12px;
  align-self: center;
}

.icon-qty {
  margin-top: 3px;
  font-size: 0.75rem;
  line-height: 13px;
  letter-spacing: -0.5px;
  display: inline-flex;
}

.ticket-qty {
  font-size: 0.75rem;
  align-self: center;
}

.ticket-detail-name {
  color: #636366;
}

.cat-visit {
  border: 1px solid #F2F5F7;
  border-radius: 8px;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
}

.sub-sub-title {
  border-bottom: none !important;
  font-size: 0.625rem;
  /* font-weight: bold;
  margin: 0 0 30px; */
  cursor: pointer;
}

.sub-sub-title span {
  vertical-align: middle;
}

.header-title {
  padding: 2vw 2vw;
  background: #F2F5F7;
  border-radius: 4px;
}

.desc-card {
  background: #F2F5F7;
  border-radius: 8px;
  padding: 4vw;
  color: #232323;
}

.desc-title {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 16px;
}

.address-redirect {
  font-size: 16px;
}

.address-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.address-city {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  color: #8E8E93;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.maps-img img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.title-event {
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 9px;
}

.banner-small {
  border: 1px solid #E5E5EA;
  border-radius: 8px;
  width: 19vw;
  height: 7.9vw;
}

.event-calendar {
  font-size: 0.75rem;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.event-calendar span {
  font-weight: 400;
  color: #000;
}

.img-banner-bg {
  background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: 100vw 51vw;
}

.container-content {
  background-color: white;
  padding: 4vw;
  margin-top: 49vw;
  border-radius: 16px 16px 0px 0px;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0vw 4.1vw 4.1vw 4.1vw;
}

.footer-form {
  width: 100%;
  padding: 4vw 4vw;
  box-shadow: 4px 4px 20px 0px #00000033;
}
</style>