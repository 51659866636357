var render = function () {
  var _vm$paymentMethod, _vm$paymentMethod2, _vm$dataPayment, _vm$dataPayment3, _vm$dataPayment4, _vm$dataEvent, _vm$dataPayment5, _vm$dataPayment5$refe, _vm$dataPayment6, _vm$dataPayment7, _vm$dataPayment8, _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "#"
    }
  }), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    staticClass: "title-page mb-3"
  }, [_vm._v("Pembayaran")]), _c('div', {
    staticClass: "card-shadow mb-3"
  }, [_c('div', {
    staticClass: "content-space-between event-calendar"
  }, [_vm._m(0), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.formatDateTimesDay(_vm.eventDate)))])]), _c('div', {
    staticClass: "title-event content-space-between"
  }, [_c('img', {
    staticClass: "banner-small me-3",
    attrs: {
      "src": _vm.imgBanner
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "w-100"
  }, [_vm._v(_vm._s(_vm.eventName))])]), _c('div', {
    staticClass: "content-space-between"
  }, [_c('p', {
    staticClass: "m-0 text-black font-size-12"
  }, [_vm._v("Selesaikan Pemesanan Dalam")]), _c('p', {
    staticClass: "m-0"
  }, [_c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.hoursTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))]), _c('span', {
    staticClass: "font-size-12 font-weight-600 text-primary"
  }, [_vm._v(" : ")]), _c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.minutesTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))]), _vm._v(" "), _c('span', {
    staticClass: "font-size-12 font-weight-600 text-primary"
  }, [_vm._v(":")]), _vm._v(" "), _c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.secondsTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))])])])]), _c('div', {
    staticClass: "card-no-shadow mb-3"
  }, [_c('div', {
    staticClass: "time-exp mb-3"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-400 text-black m-0 mb-1"
  }, [_vm._v(" Lakukan pembayaran sebelum ")]), _c('p', {
    staticClass: "font-size-12 font-weight-600 m-0 badge-grey-container"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTimes(_vm.expired_date)) + " ")])]), _c('div', {
    staticClass: "method-pay mb-3"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-400 text-black m-0 mb-2"
  }, [_vm._v(" Metode Pembayaran ")]), _c('div', {
    staticClass: "font-size-14 font-weight-600 m-0 content-space-between col-centered"
  }, [_c('img', {
    staticClass: "logo-va me-2",
    attrs: {
      "src": (_vm$paymentMethod = _vm.paymentMethod) === null || _vm$paymentMethod === void 0 ? void 0 : _vm$paymentMethod.logo
    }
  }), _c('p', {
    staticClass: "w-100 m-0"
  }, [_vm._v(_vm._s((_vm$paymentMethod2 = _vm.paymentMethod) === null || _vm$paymentMethod2 === void 0 ? void 0 : _vm$paymentMethod2.name))])])]), _c('div', {
    staticClass: "va-num mb-3"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-400 text-black m-0 mb-1"
  }, [_vm._v(" Nomor Virtual Account ")]), _c('div', {
    staticClass: "m-0 badge-grey-container content-space-between"
  }, [_c('div', {
    staticClass: "m-0 font-size-16 font-weight-600"
  }, [_vm._v(_vm._s((_vm$dataPayment = _vm.dataPayment) === null || _vm$dataPayment === void 0 ? void 0 : _vm$dataPayment.payment_account_no))]), _c('div', {
    staticClass: "m-0 font-size-12 font-weight-600 text-primary cursor-pointer",
    on: {
      "click": function ($event) {
        var _vm$dataPayment2;
        return _vm.copyNum((_vm$dataPayment2 = _vm.dataPayment) === null || _vm$dataPayment2 === void 0 ? void 0 : _vm$dataPayment2.payment_account_no);
      }
    }
  }, [_vm._v("Salin "), _c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-copy-alt.png")
    }
  })])])]), _c('div', {
    staticClass: "total-amt mb-3"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-400 text-black m-0 mb-1"
  }, [_vm._v(" Total Pembayaran ")]), _c('div', {
    staticClass: "m-0 badge-grey-container content-space-between"
  }, [_c('div', {
    staticClass: "m-0 font-size-16 font-weight-600"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$dataPayment3 = _vm.dataPayment) === null || _vm$dataPayment3 === void 0 ? void 0 : _vm$dataPayment3.total_amount)))]), _c('div', {
    staticClass: "m-0 font-size-12 font-weight-600 text-primary cursor-pointer",
    on: {
      "click": function ($event) {
        _vm.showDetail = true;
      }
    }
  }, [_vm._v("Rincian")])])]), _vm._m(1)]), _c('div', {
    staticClass: "font-size-12 font-weight-700 mb-2"
  }, [_vm._v("Cara Pembayaran")]), _vm._l((_vm$dataPayment4 = _vm.dataPayment) === null || _vm$dataPayment4 === void 0 ? void 0 : _vm$dataPayment4.instruction_payment, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "info-payment mb-2"
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'instruct_' + key,
        expression: "'instruct_' + key"
      }],
      staticClass: "header-title"
    }, [_c('p', {
      staticClass: "sub-sub-title text-dark font-weight-600 title-page d-flex m-0"
    }, [_c('span', {
      staticClass: "w-100"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.title))]), _vm._m(2, true), _vm._m(3, true)])]), _c('b-collapse', {
      staticClass: "container-exp",
      attrs: {
        "id": 'instruct_' + key
      }
    }, [_c('div', {
      staticClass: "m-0 p-2 font-size-10",
      domProps: {
        "innerHTML": _vm._s(_vm.getMarked(item === null || item === void 0 ? void 0 : item.detail))
      }
    })])], 1);
  })], 2)]), _c('div', {
    class: ['footer-form', {
      'rounded-footer': _vm.showDetail
    }]
  }, [_vm.showDetail == true ? _c('div', {
    staticClass: "container-summary"
  }, [_c('div', {
    staticClass: "summary-detail mb-2"
  }, [_c('div', {
    staticClass: "summary-title font-weight-600"
  }, [_vm._v("Rincian Pembayaran")]), _c('b-icon-x-lg', {
    staticClass: "summary-detail-close cursor-pointer",
    on: {
      "click": function ($event) {
        _vm.showDetail = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "card-no-shadow mb-3"
  }, [_c('div', {
    staticClass: "container-ticket"
  }, [_c('img', {
    staticClass: "icon-12 me-1",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket-grey.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v("Tiket Kamu")]), _vm._l((_vm$dataEvent = _vm.dataEvent) === null || _vm$dataEvent === void 0 ? void 0 : _vm$dataEvent.order_summary, function (item, key) {
    return _c('div', {
      key: key
    }, [_c('div', {
      staticClass: "sub-total-ticket"
    }, [_c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.category_name))]), _c('span', [_vm._v(_vm._s(_vm.formatCurrency((item === null || item === void 0 ? void 0 : item.sum_price) / (item === null || item === void 0 ? void 0 : item.sum_quantity))))])]), _c('div', {
      staticClass: "total-ticket mb-2"
    }, [_c('span', {
      staticClass: "font-weight-700 font-size-12"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.sum_quantity) + "x " + _vm._s(item === null || item === void 0 ? void 0 : item.category_name))]), _c('span', {
      staticClass: "font-weight-700 font-size-12"
    }, [_vm._v(_vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.sum_price)))])])]);
  }), _c('div', {
    staticClass: "total-ticket mb-3 mt-3"
  }, [_c('span', {
    staticClass: "font-weight-400 font-size-12"
  }, [_vm._v("Diskon Promo " + _vm._s((_vm$dataPayment5 = _vm.dataPayment) === null || _vm$dataPayment5 === void 0 ? void 0 : (_vm$dataPayment5$refe = _vm$dataPayment5.referral_code_item) === null || _vm$dataPayment5$refe === void 0 ? void 0 : _vm$dataPayment5$refe.discount_percentage) + "%")]), _c('span', {
    staticClass: "font-weight-600 font-size-12"
  }, [_vm._v("- " + _vm._s(_vm.formatCurrency((_vm$dataPayment6 = _vm.dataPayment) === null || _vm$dataPayment6 === void 0 ? void 0 : _vm$dataPayment6.price_reduction)))])]), _c('div', {
    staticClass: "total-ticket mb-3 mt-3"
  }, [_c('span', {
    staticClass: "font-weight-400 font-size-12"
  }, [_vm._v("Biaya Layanan")]), _c('span', {
    staticClass: "font-weight-600 font-size-12"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$dataPayment7 = _vm.dataPayment) === null || _vm$dataPayment7 === void 0 ? void 0 : _vm$dataPayment7.admin_fee)))])]), _c('div', {
    staticClass: "summary-qty mt-2"
  }, [_c('span', [_vm._v("Total Pembayaran")]), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$dataPayment8 = _vm.dataPayment) === null || _vm$dataPayment8 === void 0 ? void 0 : _vm$dataPayment8.total_amount)))])])], 2)])]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.gotoEvent();
      }
    }
  }, [_vm._v(" Detail Event ")])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  }), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('b-modal', {
    ref: "confirm-modal",
    attrs: {
      "size": "sm",
      "id": "confirm-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-600 mb-2"
  }, [_vm._v("Apakah Anda yakin ingin meninggalkan halaman ini?")]), _c('p', {
    staticClass: "font-size-10 font-weight-400 mb-4"
  }, [_vm._v("Anda harus membatalkan pesanan terlebih dahulu untuk meninggalkan halaman ini")]), _c('div', {
    staticClass: "w-100 me-0 d-flex flex-row justify-content-end align-items-center"
  }, [_c('button', {
    staticClass: "btn button-default text-primary me-3 font-size-10 font-weight-600",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('confirm-modal');
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "btn button-rounded bg-danger font-size-10 font-weight-600 btn-xxsm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/detail-order');
      }
    }
  }, [_vm._v("Cancel Order")])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    staticClass: "me-2 icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-calendar-grey.png")
    }
  }), _c('span', [_vm._v("Tanggal Event")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toast-fixed info"
  }, [_c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('span', {
    staticClass: "ms-2"
  }, [_vm._v("Lakukan pembayaran sesuai dengan Bank dari Virtual Account yang dipilih")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "icon-arrow when-closed"
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "icon-arrow when-open"
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })]);
}]

export { render, staticRenderFns }