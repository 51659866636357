<template>
  <div class="container-page">
    <HeaderComp title="" url="/" isBackToEvent />
    <div class="card-page">
      <div class="form-order">
        <div class="title-page mb-3">Lihat E-Ticket</div>
        <div class="mb-2">
          <div class="form-floating">
            <input type="text" :class="['form-control', { 'border-danger': $v.formOrderer.ticket_code.$error }]"
              id="name" placeholder="Masukkan Nomor Tiket" v-model="$v.formOrderer.ticket_code.$model">
            <label for="name">Nomor Tiket <span class="text-danger required-label">*</span></label>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="$v.formOrderer.ticket_code.$error">
            <p class="m-0" v-if="!$v.formOrderer.ticket_code.required">Nama tiket tidak boleh kosong</p>
          </div>
        </div>
        <div class="mb-2">
          <div class="form-floating">
            <input type="email" :class="['form-control', { 'border-danger': $v.formOrderer.ticket_email.$error }]"
              id="email" placeholder="Masukkan Alamat Email Anda" v-model="$v.formOrderer.ticket_email.$model">
            <label for="email">Alamat Email <span class="text-danger required-label">*</span></label>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="$v.formOrderer.ticket_email.$error">
            <p class="m-0" v-if="!$v.formOrderer.ticket_email.required">Alamat email tidak boleh kosong</p>
            <p class="m-0" v-if="!$v.formOrderer.ticket_email.email">Format alamat email tidak valid</p>
          </div>
        </div>
      </div>
      <div class="cp-card">
        <div class="cp-card-title">Contact person</div>
        <div class="cp-card-val">
          <img class="cp-card-icon me-2" src="../assets/img/instagram.png">
          <span class="cp-card-val me-2">@metalgo.id</span>
          <img class="cp-card-icon cursor-pointer" src="../assets/img/icon-copy.png" @click="copyData('https://www.instagram.com/metalgo.id')">
        </div>
        <img class="cp-card-icon-sep" src="../assets/img/icon-separator.png">
        <div class="cp-card-val">
          <img class="cp-card-icon me-2" src="../assets/img/icon-wa.png">
          <span class="cp-card-val me-2">+62812 8911 8777</span>
          <img class="cp-card-icon cursor-pointer" src="../assets/img/icon-copy.png" @click="copyData('https://wa.me/6281289118777')">
        </div>
      </div>
    </div>
    <div class="footer-form">
      <button class="btn btn-primary w-100" type="button" :disabled="$v.formOrderer.$invalid" @click="checkTicket()">
        Lihat E-Ticket
      </button>
    </div>
    <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
        <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
        <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
        <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
          Kasih</button>
      </div>
    </b-modal>
    <Loading :isLoading="loading" />
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  components: {
    Loading,
    HeaderComp,
  },
  data() {
    return {
      showDetail: false,
      isDisabledRef: false,
      loading: false,
      formOrderer: {
        ticket_code: "",
        ticket_email: ""
      },
      error: {
        title: "",
        message: ""
      }
    };
  },
  validations: {
    formOrderer: {
      ticket_code: { required },
      ticket_email: { required, email }
    }
  },
  methods: {
    copyData(val){
      navigator.clipboard.writeText(val);
    },
    checkTicket() {
      this.loading = true;
      this.$v.formOrderer.$touch();
      if (this.$v.formOrderer.$error) {
        this.loading = false;
        return false;
      }

      axios.post("/ticket", this.formOrderer).then((res) => {
        let token = res?.data?.data?.ticket_token;
        localStorage.setItem('ticket_token', token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        this.$router.push('/e-ticket');
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error?.response?.data?.title || error?.response?.data?.message) {
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.$router.push('/busy-server');
        }
      });

    }
  },
  computed: {

  },
  mounted() {
    localStorage.removeItem('ticket_token');
    localStorage.setItem('currentPath', '/check-ticket');
  },
};
</script>

<style lang="scss" scoped>
.cp-card-title {
  font-size: 0.625rem;
  font-weight: 600;
}

.cp-card-icon {
  width: 0.75rem;
  height: 0.75rem;
}

.cp-card-icon-sep {
  height: 0.75rem;
}

.cp-card-val {
  font-size: 0.625rem;
  height: fit-content;
}

.cp-card {
  background: #F2F5F7;
  padding: 0.625rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}

.sub-title-container {
  font-size: 0.625rem;
  font-weight: 400;
  color: #000;
}

.disabled-qty {
  color: #647179 !important;
  cursor: not-allowed;
}

.rounded-footer {
  border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
}

.message-claimed img {
  width: 3.59vw;
  height: 3.59vw;
}

.message-claimed span {
  font-size: 0.625rem;
}

.total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.container-ticket i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}

.container-ticket {
  padding: 2vw;
  font-size: 0.75rem;
}

.container-ticket-card {
  padding-top: 9px;
  font-size: 0.75rem;
  color: #000;
}

.container-ticket-card i {
  font-size: 0.75rem;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  color: #AEAEB2;
}

.card-summary {
  padding: 2vw;
  border: 1px solid #E7ECF4;
  border-radius: 8px;
}

.summary-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.summary-detail i {
  font-size: 1rem;
  color: #8E8E93;
}

.summary-qty {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.link-text {
  text-decoration: underline;
}

.disclaimer-text {
  padding: 2vw;
  font-size: 0.625rem;
}

.text-qty {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-align: center;
  display: inline-flex;
}

.icon-arrow {
  font-size: 0.75rem;
  line-height: 12px;
  align-self: center;
}

.icon-qty {
  margin-top: 3px;
  font-size: 0.75rem;
  line-height: 13px;
  letter-spacing: -0.5px;
  display: inline-flex;
}

.ticket-qty {
  font-size: 0.75rem;
  align-self: center;
}

.ticket-detail-name {
  color: #636366;
}

.cat-visit {
  border: 1px solid #F2F5F7;
  border-radius: 8px;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
}

.sub-sub-title {
  border-bottom: none !important;
  font-size: 0.625rem;
  /* font-weight: bold;
  margin: 0 0 30px; */
  cursor: pointer;
}

.sub-sub-title span {
  vertical-align: middle;
}

.header-title {
  padding: 2vw 2vw;
  background: #F2F5F7;
  border-radius: 4px;
}

.desc-card {
  background: #F2F5F7;
  border-radius: 8px;
  padding: 4vw;
  color: #232323;
}

.desc-title {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 16px;
}

.address-redirect {
  font-size: 16px;
}

.address-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.address-city {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  color: #8E8E93;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.maps-img img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.title-event {
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.banner-small {
  border: 1px solid #E5E5EA;
  border-radius: 8px;
  width: 19vw;
  height: 7.9vw;
}

.event-calendar {
  font-size: 0.75rem;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.event-calendar span {
  font-weight: 400;
  color: #000;
}

.event-calendar i {
  color: #AEAEB2;
}

.img-banner-bg {
  background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: 100vw 51vw;
}

.container-content {
  background-color: white;
  padding: 4vw;
  margin-top: 49vw;
  border-radius: 16px 16px 0px 0px;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0vw 4.1vw 4.1vw 4.1vw;
}

.footer-form {
  width: 100%;
  padding: 4vw 4vw;
  box-shadow: 4px 4px 20px 0px #00000033;
}
</style>