<template>
  <div class="container-page">
    <HeaderComp title="" url="/detail-order" isConfirmOrder />
    <div class="card-page">
      <div class="form-order">
        <div class="title-page mb-3">Pembayaran</div>
        <div class="card-shadow mb-3">
          <div class="content-space-between event-calendar">
            <div><img src="../assets/img/icon/fi-rr-calendar-grey.png" class="me-2" /><span>Tanggal Event</span></div>
            <span>{{
              formatDateTimesDay(eventDate) }}</span>
          </div>
          <div class="title-event content-space-between">
            <img :src="imgBanner" class="banner-small me-3"> <span class="w-100">{{ eventName }}</span>
          </div>
          <div class="content-space-between">
            <p class="m-0 text-black font-size-12">Selesaikan Pemesanan Dalam</p>
            <p class="m-0"><span class="badges-timer m-0">{{
              hoursTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span><span
                class="font-size-12 font-weight-600 text-primary"> : </span><span class="badges-timer m-0">{{
                  minutesTimer.toLocaleString('en-US',
                    { minimumIntegerDigits: 2, useGrouping: false }) }}</span> <span
                class="font-size-12 font-weight-600 text-primary">:</span> <span class="badges-timer m-0">{{
                  secondsTimer.toLocaleString('en-US',
                    { minimumIntegerDigits: 2, useGrouping: false }) }}</span></p>
          </div>
        </div>
        <div class="card-shadow-total mb-3">
          <div class="container-ticket">
            <img src="../assets/img/icon/fi-rr-ticket-grey.png" class="icon-12 me-2" /> <span
              class="font-weight-600">Tiket Kamu</span>
            <div class="mt-2">
              <div v-for="(item, key) in groupedOrderSummary" :key="key">
                <div class="sub-total-ticket">
                  <span>{{ item?.category_name }}</span>
                  <span>{{ formatCurrency((item?.sum_price / item?.sum_quantity)) }}</span>
                </div>
                <div class="total-ticket mb-1">
                  <span class="font-weight-700 font-size-12">{{ item?.sum_quantity }}x {{ item?.category_name }}</span>
                  <span class="font-weight-700 font-size-12">{{ formatCurrency(item?.sum_price) }}</span>
                </div>
              </div>
            </div>
            <div class="total-ticket " v-show ="this.promoCal != 0">
              <span class="font-weight-400 font-size-12">Diskon Promo ({{ this.promoPercent }}%)</span>
              <span class="font-weight-600 font-size-12">-{{ formatCurrency(this.promoCal) }}</span>
            </div>
            <div class="toast-fixed info mb-3 mt-3" v-if="!paymentMethod">
              <div class="message-claimed">
                <img src="../assets/img/fi-rr-info.png">
                <span class="ms-2">Total Pembayaran tiket belum termasuk Biaya Layanan pada masing-masing metode
                  pembayaran yang digunakan</span>
              </div>
            </div>
            <div class="total-ticket admin-fee mb-3 " v-else>
              <span class="font-weight-400 font-size-12">Biaya Layanan</span>
              <span class="font-weight-600 font-size-12">{{ formatCurrency(admin_fee) }}</span>
            </div>
            <div class="summary-qty mt-2">
              <span>Total Pembayaran</span>
              <span class="font-weight-600">{{ formatCurrency(totalAmount) }}</span>
            </div>

          </div>
        </div>

        <div class="promo-container">
          <span class="font-size-12 font-weight-600 mb-10">Punya Kode Promo?</span>
          <div class="promo-input-container">
            <div class="input-wrapper">
              <img src="../assets/img/icon/promo-icon.png" alt="Promo Icon" class="promo-icon" />
              <input type="text" id="promoCode" v-model="promoCode" placeholder="Masukkan Kode Promo"
                class="promo-input" />
            </div>
            <button @click="checkPromo" class="promo-button">
              Pakai
            </button>
          </div>
          <span class="error-msg">{{  this.error.message }}</span>
          <span class="success-msg">{{  this.sucessMsg }}</span>
        </div>



        <div class="font-size-12 font-weight-600 mb-2">Pilih Metode Pembayaran</div>
        <div class="card-disclaimer content-space-between mb-2" v-if="discounts.length > 0">
          <img src="../assets/img/icon/fi-rr-label.png" class="me-1 icon-12"><span
            class="font-size-10 font-weight-400 text-black w-100">Dapatkan
            potongan 25%
            untuk pembayaran menggunakan kartu kredit Bank Mandiri bagi 200 tiket pertama</span>
        </div>
        <div class="container-cat mb-2">
          <div class="header-title" v-b-toggle.credit active>
            <p class="sub-sub-title text-dark font-weight-600 title-page d-flex m-0">
              <span class="w-100">Kartu Kredit</span>
              <span class="icon-arrow when-closed"><img src="../assets/img/icon/fi-rr-angle-small-down.png"
                  class="icon-12" /></span>
              <span class="icon-arrow when-open"><img src="../assets/img/icon/fi-rr-angle-small-up.png"
                  class="icon-12" /></span>
            </p>
          </div>
          <b-collapse class="container-exp" id="credit">
            <div v-for="(item, key) in listPayment" :key="key">
              <div class="card-payment-method content-space-between mt-2" v-if="item?.type_channel == 'credit-card'">
                <div class="bg-logo-radio pe-1 ps-1 me-2"><img :id="'img_' + item?.payment_id" class="logo-radio"
                    :src="item?.logo">
                </div><span class="font-size-12 font-weight-400 text-black payment-name w-100">{{ item?.name }}</span>
                <input class="form-check-input" type="radio" name="payment" :id="item?.payment_id" :value="item"
                  aria-label="..." v-model="paymentMethod">
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="container-cat mb-2">
          <div class="header-title" v-b-toggle.va>
            <p class="sub-sub-title text-dark font-weight-600 title-page d-flex m-0">
              <span class="w-100">Virtual Account</span>
              <span class="icon-arrow when-closed"><img src="../assets/img/icon/fi-rr-angle-small-down.png"
                  class="icon-12" /></span>
              <span class="icon-arrow when-open"><img src="../assets/img/icon/fi-rr-angle-small-up.png"
                  class="icon-12" /></span>
            </p>
          </div>
          <b-collapse class="container-exp" id="va">
            <div v-for="(item, key) in listPayment" :key="key">
              <div class="card-payment-method content-space-between mt-2" v-if="item?.type_channel !== 'credit-card'">
                <div class="bg-logo-radio pe-1 ps-1 me-2"><img :id="'img_' + item?.payment_id" class="logo-radio"
                    :src="item?.logo">
                </div><span class="font-size-12 font-weight-400 text-black payment-name w-100">{{ item?.name }}</span>
                <input class="form-check-input" type="radio" name="payment" :id="item?.payment_id" :value="item"
                  aria-label="..." v-model="paymentMethod">
              </div>
            </div>
          </b-collapse>
        </div>
        <!-- <div class="card-payment-method content-space-between mt-2" v-for="(item, key) in listPayment" :key="key">
          <div class="bg-logo-radio pe-1 ps-1 me-2"><img :id="'img_' + item?.payment_id" class="logo-radio"
              :src="item?.logo">
          </div><span class="font-size-12 font-weight-400 text-black payment-name w-100">{{ item?.name }}</span>
          <input class="form-check-input" type="radio" name="payment" :id="item?.payment_id" :value="item"
            aria-label="..." v-model="paymentMethod">
        </div> -->
        <!-- <div class="card-payment-method content-space-between">
          <div class="font-size-12 font-weight-400 text-black"><img class="logo-radio me-2"
              src="../assets/img/logo-cc.png">Pakai Kartu Kredit</div>
          <input class="form-check-input" type="radio" name="payment" id="cc" value="cc" aria-label="..."
            v-model="paymentMethod">
        </div> -->
      </div>
    </div>
    <div class="footer-form">
      <button class="btn btn-primary w-100" type="button" :disabled="paymentMethod == null" @click="createPayment()">
        Bayar Pesanan
      </button>
    </div>
    <Loading :isLoading="loading" />
    <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
        <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
        <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
        <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
          Kasih</button>
      </div>
    </b-modal>
    <b-modal size="sm" ref="confirm-modal" id="confirm-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <p class="font-size-12 font-weight-600 mb-2">Apakah Anda yakin ingin meninggalkan halaman ini?</p>
        <p class="font-size-10 font-weight-400 mb-4">Anda harus membatalkan pesanan terlebih dahulu untuk meninggalkan
          halaman ini</p>
        <div class="w-100 me-0 d-flex flex-row justify-content-end align-items-center">
          <button type="button" class="btn button-default text-primary me-3 font-size-10 font-weight-600"
            @click="$bvModal.hide('confirm-modal')">Tetap Disini</button>
          <button type="button" class="btn button-rounded bg-danger font-size-10 font-weight-600 btn-xxsm"
            @click="BackToEventWithActiveRoom()">Cancel Order</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import { Decimal } from 'decimal.js';
export default {
  components: {
    Loading,
    HeaderComp,
    Decimal
  },
  data() {
    return {
      showDetail: false,
      isDisabledRef: false,
      loading: false,
      cancelOrder: false,
      paymentMethod: null,
      dataEvent: null,
      admin_fee: 0,
      listPayment: [],
      order_summary: [],
      intervalData: null,
      hoursTimer: 0,
      minutesTimer: 0,
      secondsTimer: 0,
      imgBanner: null,
      eventDate: localStorage.getItem('event_date'),
      eventName: localStorage.getItem('event_name'),
      order_id: localStorage.getItem('order_id'),
      platform_key: localStorage.getItem('platform_key'),
      expired_date: null,
      totalAmount: 0,
      error: {
        title: "",
        message: ""
      },
      sucessMsg: "",
      discounts: [],
      promoCode: '',
      promoCal: 0,
      originalAmount: 0,
      promoPercent: 0,
      referral_code_item:{
        event_id:"",
        referral_code_id:"",
        referral_name:"",
        referral_code:"",
        discount_percentage:0
    },
    isSuccessApply : false
    };
  },
  methods: {
    countdownTimer(date) {
      // let countdown = 30 * 60 * 1000;
      let like = this;
      var countDownDate = new Date(date).getTime();
      this.intervalData = setInterval(function () {

        var now = new Date().getTime();

        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(like.intervalData);
          // const platform_key = localStorage.getItem('platform_key');
          // const token = localStorage.setItem('token', token);
          // if (platform_key == 'MGO_IOS' || platform_key == 'MGO_ANDROID') {
          //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          //   if (/android/i.test(userAgent)) {
          //     JSBridge.deleteToken(token); // eslint-disable-line
          //   } else {
          //     window.webkit.messageHandlers.deleteToken.postMessage(token);
          //   }
          // }
          const base_url_event = localStorage.getItem('base_url_event');
          localStorage.clear();
          window.location.href = base_url_event;
        } else {
          like.hoursTimer = hours;
          like.minutesTimer = minutes;
          like.secondsTimer = seconds;
        }
      }, 1000);
    },
    getPaymentMethod() {
      this.loading = true;
      axios.get("/buyers/orders/payments/methods", { params: { platform_key: this.platform_key } }).then((res) => {
        this.listPayment = res.data.data[0].category_list;
        this.getDetailOrder();
      }).catch((error) => {
        this.loading = false;
        if (error?.response?.data?.title || error?.response?.data?.message) {
          this.getDetailOrder();
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.loading = false;
          this.getDetailOrder();
          this.$router.push('/busy-server');
        }
      });
    },
  checkPromo() {
  this.error.message = "";
  this.sucessMsg = "";
  this.promoCal = 0;
  this.totalAmount = this.originalAmount;
  this.paymentMethod = null;

  if (this.promoCode !== "") {
    this.loading = true;
    const url_event = localStorage.getItem("url_event");
    let event_id = localStorage.getItem('event_id');

    axios
      .get("/buyers/events/" + url_event + "/referral-code/" + this.promoCode)
      .then((res) => {
        this.promoPercent = res.data.data.discount_percentage;
        this.promoCalculate();
        this.sucessMsg = "Selamat! Kode Kamu Berhasil Digunakan.";
        this.referral_code_item.event_id = event_id;
        this.referral_code_item.referral_code_id = res.data.data._id;
        this.referral_code_item.referral_name = res.data.data.referral_name;
        this.referral_code_item.referral_code = res.data.data.referral_code;
        this.referral_code_item.discount_percentage = res.data.data.discount_percentage;
        this.isSuccessApply = true;
        this.loading = false;
      })
      .catch((error) => {
        this.error.message = error?.response?.data?.message;
        this.loading = false;
      });
  } else {
    this.error.message = "Oops! Referral code kosong";
  }
},
promoCalculate() {
  let promoCalculate = this.originalAmount * (this.promoPercent / 100);
  const roundedPromo = Number(promoCalculate.toFixed(2));
  this.promoCal = Math.floor(roundedPromo);
  let totalAmountCal = this.originalAmount - this.promoCal;
  this.totalAmount = Number(totalAmountCal.toFixed(2));
},


    getDetailOrder() {
      this.loading = true;
      axios.get("/buyers/orders/detail").then((res) => {
        this.dataEvent = res.data.data;
        localStorage.setItem('detailOrder', JSON.stringify(this.dataEvent));
        this.order_summary = res.data.data.order_summary;
        this.expired_date = res.data.data.order_expired_date;
        this.totalAmount = res.data.data.total_price;
        this.originalAmount = res.data.data.total_price;
        this.countdownTimer(this.expired_date);
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error?.response?.data?.status == 401) {
          const platform_key = localStorage.getItem('platform_key');
          const url_event = localStorage.getItem('url_event')
          this.$router.push({ name: "ChooseTicket", params: { name: url_event }, query: { platform_key: platform_key } })
        } else if (error?.response?.data?.status == 502) {
          this.loading = false;
          this.$router.push('/busy-server');
        } else if (error?.response?.data?.title || error?.response?.data?.message) {
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.loading = false;
          this.$router.push('/busy-server');
        }
      });
    },
    createPayment() {
      // if(this.paymentMethod.payment_id == 802){
      this.loading = true;

      let param = (this.isSuccessApply? { payment_channel: this.paymentMethod.payment_id,
        referral_code_item: this.referral_code_item  } : {payment_channel: this.paymentMethod.payment_id})

      axios.post("/buyers/orders/pay", param).then((res) => {
        localStorage.setItem('lastPathData', 'payment-method');
        localStorage.setItem('stepOrder', 'payment');
        localStorage.setItem('dataPayment', JSON.stringify(res.data.data));
        localStorage.setItem('choosePayment', JSON.stringify(this.paymentMethod));
        const billkey = res?.data?.data?.bill_key;
        const billcode = res?.data?.data?.bill_code;
        const type_transaction = res?.data?.data?.type_transaction;
        const request_cc = res?.data?.data?.request_credit_card;
        const token = localStorage.getItem('token');
        if (type_transaction == 'credit_card') {
          this.loading = true;
          setTimeout(() => {
            this.$router.push({ name: "CheckStatus", params: { auth_token: token } });
            this.postFasPay(request_cc);
          }, 1000);
        } else {
          // const platform_key = localStorage.getItem('platform_key');
          // const token = localStorage.getItem('token');
          // if (platform_key == 'MGO_IOS' || platform_key == 'MGO_ANDROID') {
          //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          //   const tokenStep = { step: 'payment', token: token };
          //   const jsonTokenStep = encodeURIComponent(JSON.stringify(tokenStep));
          //   if (/android/i.test(userAgent)) {
          //     JSBridge.updateToken(jsonTokenStep); // eslint-disable-line
          //   } else {
          //     window.webkit.messageHandlers.updateToken.postMessage(jsonTokenStep);
          //   }
          // }
          this.loading = false;
          this.$router.push('/payment-detail');
          // window.location.href = `https://mobile.everest.bankmandiri.co.id/uat3/customer/ecosystem/lifestyle/payment?billerCode=${billcode}&billKey1=${billkey}`;
        }
      }).catch((error) => {
        this.loading = false;
        if (error?.response?.data?.title || error?.response?.data?.message) {
          this.error.title = error?.response?.data?.title;
          this.error.message = error?.response?.data?.message;
          this.$bvModal.show('failed-modal');
        } else {
          this.loading = false;
          this.$router.push('/busy-server');
        }
      });
    },
    BackToEventWithActiveRoom() {
      let event_id = localStorage.getItem('event_id');
      let form_id = localStorage.getItem('form_id');
      let base_url_event = localStorage.getItem('base_url_event');

      axios.post("/buyers/orders/exit-room/" + event_id, { form_id: form_id }).then((res) => {
        console.log(res)
        localStorage.clear();
        window.location.href = base_url_event;
      }).catch((e) => {
        console.log(e)
        localStorage.clear();
        window.location.href = base_url_event;
      })
    },
    postFasPay(data) {
      var form = document.createElement("form");
      form.setAttribute("name", "form");
      form.setAttribute("method", "post");
      if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        form.setAttribute("target", "_blank");
      }

      form.setAttribute("action", "https://fpg.faspay.co.id/payment");

      const token = localStorage.getItem('token');
      data.RETURN_URL = window.location.origin + '/check-status/' + token;

      const body = data;

      Object.keys(body).forEach(key => {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", body[key]);

        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    }
  },
  beforeDestroy() {
    localStorage.setItem('lastPathData', 'payment-method');
    localStorage.setItem('confirmBack', 'false');
    clearInterval(this.intervalData);
  },
  computed: {
    groupedOrderSummary() {
      const grouped = {};

      this.order_summary.forEach(item => {
        if (grouped[item.category_id]) {
          grouped[item.category_id].sum_quantity += item.sum_quantity;
          grouped[item.category_id].sum_price += item.sum_price;
        } else {
          grouped[item.category_id] = { ...item };
        }
      });

      return Object.values(grouped);
    }
  },
  mounted() {
    this.getPaymentMethod();
    localStorage.setItem('currentPath', '/payment-method');
    let banner = localStorage.getItem('banner_new');
    const discounts = localStorage.getItem('discounts');

    if (banner) {
      let bannerSet = JSON.parse(localStorage.getItem('banner_new'));
      this.imgBanner = bannerSet;
    }

    if (discounts) {
      let discountSet = JSON.parse(localStorage.getItem('discounts'));
      this.discounts = discountSet;
    }

    let path = localStorage.getItem('lastPathData');
    let stepOder = localStorage.getItem('stepOrder');
    if (path) {
      if (path == 'payment-detail') {
        if (stepOder && stepOder == 'payment') {
          this.$router.push('/payment-detail');
        }
      }
    }
  },
  watch: {
    paymentMethod: function (val) {

      let admin_fee_fix = new Decimal(val.fee);
      let admin_fee_percentage = new Decimal(val.percentage);
      let totalPrice = new Decimal(this.dataEvent?.total_price);
      let promoCal = new Decimal(this.promoCal || 0); // Promo

      let totalWithPromo = totalPrice.minus(promoCal).toDecimalPlaces(0).toNumber();
      let admin_fee_percent_result = Decimal.ceil(Decimal.div(totalWithPromo, 100).times(admin_fee_percentage));

      if (val.type_fee == 'fixed') {
        this.admin_fee = admin_fee_fix;
        this.totalAmount = Decimal.add(totalWithPromo, this.admin_fee)
      } else if (val.type_fee == 'mixed') {
        this.admin_fee = Decimal.add(admin_fee_percent_result, admin_fee_fix);
        this.totalAmount = Decimal.add(totalWithPromo, this.admin_fee)
      } else if (val.type_fee == 'percentage') {
        this.admin_fee = admin_fee_percent_result;
        this.totalAmount = Decimal.add(totalWithPromo, this.admin_fee)
      } else if (val.type_fee == "no-fee") {
        this.admin_fee = 0;
        this.totalAmount = Decimal.add(totalWithPromo, this.admin_fee)
      }
      else {
        this.admin_fee = 0;
        this.totalAmount = Decimal.add(totalWithPromo, this.admin_fee)
      }

    }
  }
};
</script>

<style lang="scss" scoped>
.event-calendar img {
  width: 0.75rem;
}

.admin-fee {
  padding-bottom: 3vw;
  border-bottom: 1px solid #E7ECF4;
}

.card-disclaimer {
  background: #F5F7FA;
  border-radius: 8px;
  padding: 3vw;
}

.payment-name {
  text-align: left;
}

.bg-logo-radio {
  background-color: #ffffff;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
}

.logo-radio {
  width: 16px;
  height: 5px;
}

.card-payment-method {
  background: #fff;
  border: 1px solid #D2D7DA;
  padding: 4vw;
  border-radius: 8px;
}

.sub-title-container {
  font-size: 0.625rem;
  font-weight: 400;
  color: #000;
}

.disabled-qty {
  color: #647179 !important;
  cursor: not-allowed;
}

.rounded-footer {
  border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
}

.message-claimed img {
  margin-top: 3px;
  width: 0.625rem;
  height: 0.625rem;
}

.message-claimed span {
  font-size: 0.625rem;
}

.total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.sub-total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #647179;
  font-weight: 400;
}

.container-ticket i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}

.container-ticket {
  font-size: 0.75rem;
}

.container-ticket-card {
  padding-top: 9px;
  font-size: 0.75rem;
  color: #000;
}

.container-ticket-card i {
  font-size: 0.75rem;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  color: #AEAEB2;
}

.card-summary {
  padding: 2vw;
  border: 1px solid #E7ECF4;
  border-radius: 8px;
}

.summary-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.summary-detail i {
  font-size: 1rem;
  color: #8E8E93;
}

.summary-qty {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.link-text {
  text-decoration: underline;
}

.disclaimer-text {
  padding: 2vw;
  font-size: 0.625rem;
}

.text-qty {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-align: center;
  display: inline-flex;
}

.icon-arrow {
  font-size: 0.75rem;
  line-height: 12px;
  align-self: center;
}

.icon-qty {
  margin-top: 3px;
  font-size: 0.75rem;
  line-height: 13px;
  letter-spacing: -0.5px;
  display: inline-flex;
}

.ticket-qty {
  font-size: 0.75rem;
  align-self: center;
}

.ticket-detail-name {
  color: #636366;
}

.cat-visit {
  border: 1px solid #F2F5F7;
  border-radius: 8px;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
}

.sub-sub-title {
  border-bottom: none !important;
  font-size: 0.625rem;
  /* font-weight: bold;
  margin: 0 0 30px; */
  cursor: pointer;
}

.sub-sub-title span {
  vertical-align: middle;
}

.header-title {
  padding: 2vw 2vw;
  background: #F2F5F7;
  border-radius: 4px;
}

.desc-card {
  background: #F2F5F7;
  border-radius: 8px;
  padding: 4vw;
  color: #232323;
}

.desc-title {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 16px;
}

.address-redirect {
  font-size: 16px;
}

.address-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.address-city {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  color: #8E8E93;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.maps-img img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.title-event {
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 9px;
}

.banner-small {
  border: 1px solid #E5E5EA;
  border-radius: 8px;
  width: 19vw;
  height: 7.9vw;
}

.event-calendar {
  font-size: 0.75rem;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.event-calendar span {
  font-weight: 400;
  color: #000;
}

.event-calendar i {
  color: #AEAEB2;
}

.img-banner-bg {
  background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: 100vw 51vw;
}

.container-content {
  background-color: white;
  padding: 4vw;
  margin-top: 49vw;
  border-radius: 16px 16px 0px 0px;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0vw 4.1vw 4.1vw 4.1vw;
}

.footer-form {
  width: 100%;
  padding: 4vw 4vw;
  box-shadow: 4px 4px 20px 0px #00000033;
}

.icon-promo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.container-input-promo {
  display: flex;
  justify-content: space-between;
}

.btn-pesan-tiket {
  padding: 15px;
  height: 52px;
  background-color: #1C6DB8;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: small;
  margin-top: 10px;

}

.form-floating {
  background: #F3F3F3;
  border: none;
  border-radius: 8px;
  height: 52px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;

}

/* Container Utama */
.promo-container {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  padding-bottom: 10px;
}

/* Judul */
.promo-title {
  display: block;
  margin-bottom: 10px;
}

/* Container Input dan Tombol */
.promo-input-container {
  display: flex;
  align-items: center;
}

/* Wrapper Input */
.input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f8fafc;
  /* Warna abu-abu */
  border-radius: 8px;
  padding: 15px 12px;
  width: 100%;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Ikon */
.promo-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Input Text */
.promo-input {
  border: none;
  outline: none;
  font-size: 14px;
  flex: 1;
  background: transparent;
  color: #333;
}

.promo-input::placeholder {
  color: #aaa;
}

/* Tombol "Pakai" */
.promo-button {
  background-color: #1a73e8;
  /* Warna biru */
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  padding: 15px 16px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.promo-button:hover {
  background-color: #1558c8;
  /* Warna biru lebih gelap saat hover */
}

.error-msg {
  font-weight: 100;
  color: red;
  margin-top: 10px;
}

.success-msg {
  font-weight: 100;
  color: #258A2F;
  margin-top: 10px;
}
</style>