import Vue from 'vue'
import App from './App.vue'
import moment from 'moment';
import router from './router/router';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import VueSweetalert2 from 'vue-sweetalert2';
import './assets/css/sweetalert2.css';

// import VueFeatherIcon from 'vue-feather-icon';
import { BootstrapVue, IconsPlugin, ModalPlugin } from 'bootstrap-vue';
import './assets/css/vue-loading.css';
import axios from 'axios';
import store from "./store/index";
import service from "./helper/service.js";

// import FlagIcon from 'vue-flag-icon';
import { languages, defaultLocale } from './locales/index.js';

// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/scss/app.scss';

import Toast from "vue-toastification";
import "./assets/css/vue-toast.css";

import "@flaticon/flaticon-uicons/css/all/all.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
})

const messages = Object.assign(languages)

// Vue.use(FlagIcon);
// Vue.use(VueFeatherIcon);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAZt030xKWWFwLGjmEebi_0Fx6-TQVxD8A',
//     libraries: 'places',
//   }
// });


// Install BootstrapVue
Vue.use(VueSweetalert2)
Vue.use(VueI18n)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(ModalPlugin)


axios.defaults.baseURL = 'https://api.tenar.events/v2';
// axios.defaults.headers.post['Language'] = 'id';
axios.defaults.headers.common['Language'] = 'id';

// const token = store.getters.token;
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     // console.log(token)
//     if (error?.response?.status) {
//       if (error?.response?.status === 401) {
//         // console.log(error.response)
//         service.logout();
//       }
//     }
//     throw error;
//   });
// Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.config.productionTip = false
Vue.use(Vuelidate);

localStorage.setItem("lang", "id");

const i18n = new VueI18n({
  locale: localStorage.getItem("lang"),
  messages
})

Vue.mixin({
  methods: {
    closeModal: function ($val) {
      this.$refs[$val].hide();
    },
    dateDisabled: function (e) {
      e.preventDefault();
    },
    formatDate: function (value) {
      if (value && value != "-") {
        moment.locale("id");
        return moment(String(value)).format("DD MMMM YYYY");
      }
    },
    getUrlBlob(val) {
      if (val) {
        let base_url = axios.defaults.baseURL;
        const sig_url = base_url + val;
        const token = localStorage.getItem("token");
        const sigRequest = fetch(sig_url, {
          method: "GET", // or 'PUT'
          headers: {
            authorization: "Bearer " + token,
          },
        }).then((response) => response.blob());

        sigRequest.then((blob) => {
          return window.URL.createObjectURL(blob);
        });
        // console.log(this.dataAttachment);
      }
    },
    getStatusBiro: function ($val) {
      if ($val == 'new') return "Verifikasi Akun";
      if ($val == 'registered') return "Full Register";
      if ($val == 'active') return "Aktif";
      if ($val == 'block') return "Diblok";
      if ($val == 'deleted') return "Dihapus";
    },
    getStatusData: function ($val) {
      if ($val == 'new') return "Lengkapi Data";
      if ($val == 'registered') return "Data Lengkap";
      if ($val == 'fill') return "Menunggu Verifikasi";
      if ($val == 'rejected') return "Perbaiki Data";
      if ($val == 'approved') return "Buat Rekening Escrow";
      if ($val == 'block') return "Diblok";
      // if ($val == 'deleted') return "Dihapus";
    },
    showToast:function ($type,$message){
      this.$toast($message, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        type:$type,
        rtl: false
      });
    },
    formatCurrency: function (val) {
      let value = val.toString();
      if (value != null && value != "") {
        let data = val.toString();
        let comma = data.includes(".");
        let datacomma = "";
        let minusCurrency = false;
        if (comma) {
          datacomma = data.split('.')[1];
          data = data.split('.')[0];
        }

        if(data.charAt(0) == '-'){
          minusCurrency = true;
        }

        let numb = data.replace(/[^0-9]/g, "");
        if (numb == "") {
          return "";
        }

        const format = numb.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        let result = convert.join('.').split('').reverse().join('');

        if (comma) {
          result += "," + datacomma;
        }

        if(minusCurrency == true){
          result = '-' + result;
        }

        return "Rp " + result;
      }
    },
    formatDateTimesDay: function (value){
      if (value && value != "-") {
        moment.locale("id");
        return moment(String(value)).format("dddd , DD MMMM YYYY");
      }
    },
    formatDateTimes: function (value){
      if (value && value != "-") {
        moment.locale("id");
        return moment(String(value)).format("dddd , DD MMMM YYYY H:mm:ss");
      }
    }
  },
})

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
